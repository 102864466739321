import { useEffect } from 'react';
import { useUser } from '@auth0/nextjs-auth0/client';
import { useAnalytics } from '../contexts/AnalyticsContext';

const useSegmentIdentify = () => {
  const analytics = useAnalytics();
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      analytics.identify(user.sub, {
        email: user.email,
        name: user.name,
        first_name: user.given_name,
        last_name: user.family_name
      });
    }
  }, [user, analytics]);
};

export default useSegmentIdentify;
