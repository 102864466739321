import styled from 'styled-components';
import { Caret } from '@src/components/icons';
import { mq, LARGE_MOBILE } from '@src/styles/mixins';

const NavButton = styled.button`
  border-radius: 50%;
  cursor: pointer;
  background: var(--cvna-color-white);
  color: var(--cvna-color-blue-2);
  box-shadow: var(--cvna-elevation-2);
  width: 58px;
  height: 58px;
  border: 1px solid transparent;
  align-items: center;
  justify-content: center;
  line-height: 0;
  transition: all 0.2s ease-in-out;

  &:focus {
    outline: none;
  }
  &:hover:enabled {
    color: var(--cvna-color-blue-3);
    box-shadow: rgba(24, 53, 88, 0.1) 0px 6px 20px;
    border-color: var(--cvna-color-grey-1);
  }
  &:active {
    border-color: var(--cvna-color-grey-1);
    box-shadow: var(--cvna-elevation-1);
    color: var(--cvna-color-blue-2);
  }

  ${mq(
    LARGE_MOBILE,
    `
    display: none;
  `
  )}

  &[disabled] {
    opacity: 0.5;
    cursor: auto;
  }
`;

const NavigationButton = ({ direction, onClick, className }) => (
  <NavButton onClick={ onClick } className={ className }>
    <Caret direction={ direction } size={ 16 } />
  </NavButton>
);

export const NextButton = ({ nextSlide, ...props }) => (
  <NavigationButton direction='right' onClick={ nextSlide } { ...props } />
);

export const PrevButton = ({ previousSlide, ...props }) => (
  <NavigationButton direction='left' onClick={ previousSlide } { ...props } />
);

export default NavigationButton;
