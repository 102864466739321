import { useEffect } from 'react';
import { useAnalytics } from '../contexts/AnalyticsContext';
import { getGtag } from '../utils/gtag';
import useSegmentIdentify from './useSegmentIdentify';

const useSegmentPage = (page, props = {}) => {
  const analytics = useAnalytics();
  useSegmentIdentify();
  useEffect(() => {
    (async () => {
      analytics.page(page, {
        ...props,
        gtag_client_id: await getGtag('client_id'),
        gtag_session_id: await getGtag('session_id')
      });
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useSegmentPage;
