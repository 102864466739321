import { useState, useEffect } from 'react';

const getWindowSize = () => ({
  height: window.innerHeight,
  width: window.innerWidth
});

export default function useOnResize() {
  const [screen, setScreen] = useState({});
  useEffect(() => setScreen(getWindowSize()), []);

  useEffect(() => {
    const handler = () => {
      setScreen(getWindowSize());
    };

    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  });

  return screen;
}
