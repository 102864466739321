import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { typeStyle } from '@carvana/showroom-css-theme';
import {
  flexAlignHorizontalContent,
  flexAlignVerticalContent,
  LARGE_MOBILE,
  mq,
} from '@src/styles/mixins';
import { saleStartEasternTime } from '@src/utils/time';
import useCid from '@hooks/useCid';
import GrayLine from '@components/GrayLine';
import Button from '@components/Button';

import AdesaClearLogo from '../../public/images/adesa_clear_logo.svg';
import AccessLogo from '../../public/images/carvana_access_logo.svg';

const SplitCTA = ({ page }) => {
  const {
    auctionSaleEndTime,
    auctionCtaLocations,
    auctionAdesaCtaLink,
    auctionAdesaCtaValueProps
  } = useFlags();
  const isMarketingPage = page === 'marketing';
  const component = isMarketingPage ? 'Marketing Page Split CTAs' : 'Today\'s Sale Split CTAs';
  const cid = isMarketingPage ? 'APP-C4PWEB-FRONTPAGE' : 'APP-C4PWEB-TODAYSSALE';
  const signInUrl = useCid(process.env.CARVANA_ACCESS_LOGIN_URL, cid);
  const saleTime = saleStartEasternTime(undefined, auctionSaleEndTime)
    .toFormat('ha').toLowerCase();

  return (
    <SplitContainer>
      <CTAContainer $isMarketingPage={ isMarketingPage }>
        <AdesaClearLogo />
        <FeatureContainer>
          <FeatureList $isMarketingPage={ isMarketingPage }>
            {auctionAdesaCtaValueProps?.map(value => (
              <FeatureItem key={ value }>{value}</FeatureItem>
            ))}
          </FeatureList>
        </FeatureContainer>
        <SaleSection $isMarketingPage={ isMarketingPage }>
          <SaleContainer>
            <CTATitle>
              Digital Sales
            </CTATitle>
            <CTAText>On ADESAClear.com all week</CTAText>
          </SaleContainer>
        </SaleSection>
        {auctionCtaLocations && (
          <LocationsContainer>
            <GrayLine margin='0 auto 24px' />
            <CTATitle>
              Locations
            </CTATitle>
            <LocationsGrid $isMarketingPage={ isMarketingPage }>
              {auctionCtaLocations.clear.map(location => (
                <CTAText key={ location }>{location}</CTAText>
              ))}
            </LocationsGrid>
          </LocationsContainer>
        )}
        <Button
          href={ auctionAdesaCtaLink || '#' }
          target='_blank'
          segmentProps={{
            event: 'ADESA Sign In Clicked',
            props: {
              component
            }
          }}
        >
          Sign In with Adesa
        </Button>
      </CTAContainer>
      <CTAContainer $isMarketingPage={ isMarketingPage }>
        <AccessLogo />
        <FeatureContainer>
          <FeatureList $isMarketingPage={ isMarketingPage }>
            <FeatureItem>
              Manheim Express Condition Report
            </FeatureItem>
            <FeatureItem>
              360° Imagery and Annotations
            </FeatureItem>
            <FeatureItem>
              Starting Bid = Floor
            </FeatureItem>
            <FeatureItem>
              95% First-Run Sales Rate
            </FeatureItem>
            <FeatureItem>
              Video and Audio CRs
            </FeatureItem>
            <FeatureItem>
              Fair and Easy Arbitrations
            </FeatureItem>
          </FeatureList>
        </FeatureContainer>
        <SaleSection $isMarketingPage={ isMarketingPage }>
          <SaleContainer>
            <CTATitle>
              Digital Daily Sale
            </CTATitle>
            <CTAText>
              Starts and ends every weekday at {saleTime} ET
            </CTAText>
          </SaleContainer>
        </SaleSection>
        {auctionCtaLocations && (
          <LocationsContainer>
            <GrayLine margin='0 auto 24px' />
            <CTATitle>
              Locations
            </CTATitle>
            <LocationsGrid $isMarketingPage={ isMarketingPage }>
              {auctionCtaLocations.access.map(location => (
                <CTAText key={ location }>{location}</CTAText>
              ))}
            </LocationsGrid>
          </LocationsContainer>
        )}
        <Button
          href={ signInUrl.href }
          target='_blank'
          segmentProps={{
            event: 'Manheim Sign In Clicked',
            props: {
              component
            }
          }}
        >
          Sign In with Manheim
        </Button>
      </CTAContainer>
    </SplitContainer>
  );
};

const SplitContainer = styled.div`
  ${flexAlignHorizontalContent('center', 'flex-start')}
  gap: 32px;

  ${mq(LARGE_MOBILE, `
    ${flexAlignVerticalContent('center', 'center')}
  `)}
`;

const CTAContainer = styled.div`
  ${flexAlignVerticalContent('center', 'flex-start')}
  gap: 24px;
  width: 100%;
  padding: 24px;
  background: ${({ $isMarketingPage }) => $isMarketingPage ? 'var(--cvna-color-grey-1)' : 'var(--cvna-color-white)'};
  border-radius: 10px;
  border: 1px solid var(--cvna-color-grey-2);
  text-align: center;
  ${typeStyle('body-s')};

  ${({ $isMarketingPage }) => !$isMarketingPage && `
    height: calc(100vh - 164px);
    ${mq(LARGE_MOBILE, 'height: auto;')}
  `}

  padding-bottom: 24px;

  > svg {
    min-height: 50px;
  }
`;

const SaleSection = styled.div`
  width: 100%;
  ${flexAlignHorizontalContent('space-around', 'flex-start')}

  ${mq('max-width: 1024px', `
    ${flexAlignVerticalContent('center', 'center')}
    gap: 32px;
  `)}

  ${({ $isMarketingPage }) => !$isMarketingPage && `
    ${flexAlignVerticalContent('center', 'center')}
    gap: 32px;
    min-height: 120px;
  `}
`;

const FeatureContainer = styled.div`
  ${flexAlignHorizontalContent('center', 'flex-start')}
  gap: 40px;
  width: 100%;

  ${mq(LARGE_MOBILE, `
    ${flexAlignVerticalContent('center', 'center')}
    gap: 0;
  `)}
`;

const FeatureList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60px;
  flex-wrap: wrap;
  width: 100%;

  ${({ $isMarketingPage }) => mq($isMarketingPage ? 'max-width: 1024px' : 'max-width: 1330px', `
    flex-wrap: no-wrap;
    height: auto;
  `)}
`;

const FeatureItem = styled.li`
  color: var(--cvna-color-blue-6);

  ::marker {
    color: var(--cvna-color-blue-2);
    font-size: 0.8em;
  }
`;

const SaleContainer = styled.div`
  ${flexAlignVerticalContent('center', 'center')}
`;

const CTATitle = styled.h2`
  ${typeStyle('header-m')};
  color: var(--cvna-color-blue-6);
`;

const CTAText = styled.p``;

const LocationsContainer = styled.div`
  ${flexAlignVerticalContent('center', 'center')}
  width: 100%;
  gap: 8px;
  flex-grow: 1;
  justify-content: flex-start;
`;

const LocationsGrid = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ $isMarketingPage }) => $isMarketingPage ? '100px' : '100%'};
  max-height: calc(100vh - 650px);
  flex-wrap: wrap;
  align-self: center;
  width: 100%;
  overflow-x: auto;
  column-gap: 4px;

  ${mq(LARGE_MOBILE, `
    height: auto;
    max-height: 350px;
  `)}
`;

SplitCTA.defaultProps = {
  page: ''
};

SplitCTA.propTypes = {
  page: PropTypes.string,
};

export default SplitCTA;
