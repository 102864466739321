import React from 'react';
import styled from 'styled-components';
import { flexAlignHorizontalContent } from '@src/styles/mixins';

const NavDotContainer = styled.div`
  ${flexAlignHorizontalContent('center', 'center')}
  cursor: pointer;
`;

const NavDotWrapper = styled.div`
  padding: 4px;
`;

const NavDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  background-color: var(--cvna-color-grey-2);
  transition: background-color 0.2s ease-in-out;

  ${({ $active }) => $active && `
    background-color: var(--cvna-color-blue-2);
  `}
`;

const PaginationDots = ({ pagingDotsIndices, currentSlide, goToSlide, className }) => {
  return (
    <NavDotContainer className={ className }>
      {pagingDotsIndices.map(idx => (
        <NavDotWrapper key={ `nav-dot-${idx}` } onClick={ () => goToSlide(idx) }>
          <NavDot $active={ idx === currentSlide } />
        </NavDotWrapper>
      ))}
    </NavDotContainer>
  );
};

export default PaginationDots;
